<template>
  <div class="app page-bg">
    <esports-header />
    <main class="container-page">
      <!-- <SectionBanner />
      <SectionPickup />
      <SectionArchive /> -->
      <SectionPhone />
      <SectionSlider class="slider_top"/>
    </main>
    <esports-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SectionPhone from "./SectionPhone";
// import SectionBanner from "./SectionBanner";
import SectionSlider from "./SectionSlider";
// import SectionPickup from "./SectionPickup";
// import SectionArchive from "./SectionArchive";

export default {
  name: "LandingPage",
  title: "landing",
  components: {
    SectionPhone,
    // SectionBanner,
    SectionSlider,
    // SectionPickup,
    // SectionArchive
  },
  computed: {
    ...mapGetters("auth", ["authenticated"]),
  },
  async mounted() {
    this.authenticated && await this.addFirstAccess();
  },
  methods: {
    async addFirstAccess() {
      await this.$store
        .dispatch("home/addFirstAccess")
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>